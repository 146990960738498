import { useAuthStore } from '@/stores/auth';
import { callWithNuxt } from '#app';

export default defineNuxtRouteMiddleware((to, from) => {
    const nuxtApp = useNuxtApp();
    // Fetch my user. If the user just get in, he will not have a accessToken => a refreshToken will be used to get a new accessToken
    const authStore = useAuthStore();
    if (authStore.isLoggedInAsClient) {
        return;
    }
    // if (authStore.isLoggedIn && authStore.isLoggedInAsPublic) {
    //     return callWithNuxt(nuxtApp, navigateTo, ['/public/login']);
    // }

    return authStore.fetchMyUser().then((response) => {
        if (!response.uid) {
            // return navigateTo('/login');
            return callWithNuxt(nuxtApp, navigateTo, ['/login']);
        }
        if (!response.organisation) {
            // return navigateTo('/');
            return callWithNuxt(nuxtApp, navigateTo, ['/']);
        }
    }).catch(() => {
        // Based on https://github.com/nuxt/nuxt/issues/14269#issuecomment-1397352832
        // to deal with callWithNuxt in middleware
        // return abortNavigation('You are not authorized to access this page.');
        return callWithNuxt(nuxtApp, abortNavigation, ['You are not authorized to access this page']);
    });
});
